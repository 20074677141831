import cours from '../images/cours.jpg';
import coeur from '../images/coeur.jpg';
import terasse from '../images/terasse_cafe.jpg';
import ter1 from '../images/TER1.jpg';
import ter2 from '../images/TER3.jpg';
import salon1 from '../images/Salon1.jpg';
import salon2 from '../images/Salon (2).jpg';
import bar1 from '../images/BAR (1).jpg';
import bar2 from '../images/BAR (2).jpg';
import bar3 from '../images/BAR (3).jpg';
import entree1 from '../images/ENTREE (1).jpg';
import entree2 from '../images/ENTREE (2).jpg';
import jardin from '../images/JAR1.jpeg'
import jardin1 from '../images/JAR2.jpeg';
import jardin2 from '../images/JAR3.jpeg';
import jardin3 from '../images/JAR4.jpeg';
import jardin4 from '../images/JAR5.jpeg';
import jardin5 from '../images/JAR6.jpeg';
import sealand from "../images/sealand.png";
import etier from "../images/etier.png";
import gois from "../images/gois.png";

const picimport = [
    {
        id: 1,
        img: cours,
        alt: "Cours",
    },
    {
        id: 2,
        img: coeur,
        alt: "Coeur",
    },
    {
        id: 3,
        img: terasse,
        alt: "Terasse",
    },
    {
        id: 4,
        img: salon1,
        alt: "Salon1",
    },
    {
        id: 5,
        img: salon2,
        alt: "Salon2",
    },
    {
        id: 6,
        img: bar1,
        alt: "Bar1",
    },
    {
        id: 7,
        img: bar2,
        alt: "Bar2",
    },
    {
        id: 8,
        img: bar3,
        alt: "Bar3",
    },
    {
        id: 9,
        img: entree1,
        alt: "Entree1",
    },
    {
        id: 10,
        img: entree2,
        alt: "Entree2",
    },
    {
        id: 11,
        img: jardin,
        alt: "Jardin",
    },
    {
        id: 12,
        img: jardin1,
        alt: "Jardin1",
    },
    {
        id: 13,
        img: jardin2,
        alt: "Jardin2",
    },
    {
        id: 14,
        img: jardin3,
        alt: "Jardin3",
    },
    {
        id: 15,
        img: jardin4,
        alt: "Jardin4",
    },
    {
        id: 16,
        img: jardin5,
        alt: "Jardin5",
    },
    {
        id: 17,
        img: ter1,
        alt: "Ter1",
    },
    {
        id: 18,
        img: ter2,
        alt: "Ter2",
    },
    {
        id: 19,
        img: sealand,
        alt: "Sealand",
    },
    {
        id: 20,
        img: etier,
        alt: "Etier",
    },
    {
        id: 21,
        img: gois,
        alt: "Gois",
    },
];

export default picimport;
